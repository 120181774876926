@import "../../styles/variables";

.btnCustom {
  :global {
    .ant-btn {
      background: $color-gradient-primary;
      border-color: $color-gradient-primary;
      min-height: 45px;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
      min-width: 120px;
      padding: 5px 5px;
      border-radius: 8px;
      justify-content: center;
      &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        color: #f0dab5;
        border-color: #f6ab67;
      }
    }
  }
}

.btnOutline {
  :global {
    .ant-btn {
      border: 1px solid $primary-color;
      background: #fff;
      color: $primary-color;
    }
  }
}

.btnNoneRound {
  :global {
    .ant-btn {
      border: 0px solid white;
      min-height: 0px;
      min-width: 0px;
      width: auto;
      height: auto;
      background: #fff;
      color: $dark-gray-color;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      padding: 0px;
      box-shadow: none;
    }
  }
}

.btnAction {
  :global {
    .ant-btn {
      width: 40px;
      height: 40px;
      border-radius: 10px;
    }
  }
}

.btnTabInactive {
  :global {
    .ant-btn {
      background: #eaeaea;
      border: 0px solid white;
      border-radius: 8px;
      padding: 12px 16px;
      min-width: 45px;
      color: $dark-gray-color;
      &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        color: $dark-gray-color;
        border-color: #f6ab67;
      }
    }
  }
}

.btnTabActive {
  :global {
    .ant-btn {
      background: #f08437;
      border-color: $color-gradient-primary;
      border: 0px solid white;
      min-height: 45px;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
      min-width: 45px;
      padding: 12px 16px;
      border-radius: 8px;
      justify-content: center;
      &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        color: #f0dab5;
        border-color: #f6ab67;
      }
    }
  }
}
