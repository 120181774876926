$header-height: 60px;
$side-nav-width: 220px;

$primary-color: #f08437;
$white-color: #fff;
$gray-color: #eeeeee;
$total-title-color: #a29490;
$dark-red-color: #a61f17;
$header-table-color: rgba($primary-color, 0.25);
$category-color: rgba($primary-color, 0.15);
$text-table-color: #4f4f4f;
$text-primary-color: #3b3b3b;
$text-second-color: #888888;
$dark-gray-color: #2e2e2e;
$color-place-holder: #818181;
$color-body-list: #f4f4f4;
$total-item-list: #ff503d;
$color-border-input: #c0c0c0;
$color-select-checked: #27ae60;
$color-gradient-primary: linear-gradient(270deg, #f6ab67 0%, #f08437 100%);
$color-seagull: #007aaf;
$color-tab-color: #eaeaea;
