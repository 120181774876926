.modalDeleteItem {
  :global {
    .ant-modal-content {
      padding: 14px 16px 16px 16px;
      border-radius: 24px;
    }
  }
  .body {
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 27px;
      padding: 6px 0px 20px 0px;
    }
    .viewBtn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding-top: 16px;
      .btnSubmit {
        :global {
          .ant-btn {
            width: 155px;
            height: 56px;
            border-radius: 16px;
          }
        }
      }
    }
  }
}

.width100 {
  width: 100%;
  gap: 16px;

  button {
    width: 100% !important;
  }
}
