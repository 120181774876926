@import "../../styles/variables";

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: $dark-gray-color;
  border-bottom: 1px #ddd solid;
  padding: 16px;
  .viewNameStore {
    display: flex;
    font-weight: 700;
    color: #fff;
    font-size: 24px;
    line-height: 36px;
    align-items: center;
    .nameStore {
      padding-left: 16px;
    }
    .logoApp {
      width: 48px;
      height: 48px;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .languages {
      min-width: 200px;
    }
    .menuWrapper {
      flex: 1;
      display: flex;
      .menuItem {
        display: flex;
        color: #fff;
        .avatar {
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }
        .interfaceDropdown {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
          .nameAccount {
            padding-left: 8px;
          }
        }
        cursor: pointer;
        .badge {
          position: absolute;
          top: 8px;
          right: 4px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          background-origin: padding-box;
          color: #fff;
          min-width: 14px;
          height: 14px;
          background-color: #eb5757;
          border-radius: 50%;
        }
        &:last-child img {
          border-radius: 50%;
          border: 1px solid #c4c4c4;
        }
      }
    }
  }
}
.dropdown {
  width: 230px;
  padding-top: 16px;
  :global {
    ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
      border-radius: 0px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 16px;
      li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
        &:hover {
          background-color: transparent;
        }
        margin-bottom: 0px;
        padding: 0px;
      }
    }
  }
}
.viewBtnAction {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  &:hover {
    background-color: $gray-color;
  }
  .iconAction {
    color: black;
    margin-right: 8px;
    width: 24px;
    height: 24px;
    &__iconLock {
      @extend .iconAction;
      path {
        stroke: $dark-gray-color;
      }
    }
  }
  &__changePassword {
    @extend .viewBtnAction;
  }
}
